import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from './guards/authenticated.guard';
import { RedirectIfAuthenticatedGuard } from './guards/redirect-if-authenticated.guard';
import { HasProviderGuard } from './guards/has-provider.guard';


const routes: Routes = [
  { path: '', loadChildren: () => import('./providers-desktop/providers-desktop.module').then( m => m.ProvidersDesktopPageModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  {
    path: 'signin',
    canActivate: [RedirectIfAuthenticatedGuard],
    loadChildren: () => import('./signin/signin.module').then( m => m.SigninPageModule)
  },
  {
    path: 'signup',
    canActivate: [RedirectIfAuthenticatedGuard],
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'reset-password',
    canActivate: [RedirectIfAuthenticatedGuard],
    loadChildren: () => import('./reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'providers',
    canActivate: [],
    loadChildren: () => import('./providers/providers.module').then( m => m.ProvidersPageModule)
  },
  {
    path: 'my-profile',
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('./my-profile/my-profile.module').then( m => m.MyProfilePageModule)
  },
  {
    path: 'my-orders',
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('./my-orders/my-orders.module').then( m => m.MyOrdersPageModule)
  },
  {
    path: 'select-area',
    canActivate: [],
    loadChildren: () => import('./select-area/select-area.module').then( m => m.SelectAreaPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'my-addresses',
    canActivate: [AuthenticatedGuard],
    loadChildren: () => import('./my-addresses/my-addresses.module').then( m => m.MyAddressesPageModule)
  },
  {
    path: 'shopping-cart',
    loadChildren: () => import('./shopping-cart/shopping-cart.module').then( m => m.ShoppingCartPageModule)
  },
  {
    path: 'product-detail',
    loadChildren: () => import('./product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'add-address',
    loadChildren: () => import('./add-address/add-address.module').then( m => m.AddAddressPageModule)
  },
  {
    path: 'order-detail',
    loadChildren: () => import('./order-detail/order-detail.module').then(m => m.OrderDetailPageModule)
  },
  {
    path: 'providers-desktop',
    loadChildren: () => import('./providers-desktop/providers-desktop.module').then( m => m.ProvidersDesktopPageModule)
  },
  {
    path: 'checkout-desktop',
    loadChildren: () => import('./checkout-desktop/checkout-desktop.module').then( m => m.CheckoutDesktopPageModule)
  },
  {
    path: 'product-detail-modal',
    loadChildren: () => import('./product-detail-modal/product-detail-modal.module').then( m => m.ProductDetailModalPageModule)
  }
];  

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
