import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ProvidersDesktopPageRoutingModule } from './providers-desktop-routing.module';

import { ProvidersDesktopPage } from './providers-desktop.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ProvidersDesktopPageRoutingModule
  ],
  providers: [
    CurrencyPipe],
  declarations: [ProvidersDesktopPage]
})
export class ProvidersDesktopPageModule {}
