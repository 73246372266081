import { FormGroup } from '@angular/forms';
import { Utils } from './utils';

export class CustomValidators {
	static required(control) {
    return !control.value || control.value.toString().trim() == "" ?
      {"required": true} :
      null;
  }

  static email(control) {
    var regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return control.value && !regex.test(control.value) ?
      {"email": true} :
      null;
  }

  static rut(control) {
    return control.value && !Utils.checkRut(control.value) ?
      {"rut": true} :
      null;
  }

  static equals(valueControlNameA: string, valueControlNameB: string) {
    return (group: FormGroup): {[key: string]: any} => {
      if (!group.parent) return null;

      let valueA = group.parent.controls[valueControlNameA].value;
      let valueB = group.parent.controls[valueControlNameB].value;

      return valueA === valueB ? null : {"equals": true}; 
    }
  }

  static hasNumber(control) {
    var regex = /[0-9]/;
    return control.value && !regex.test(control.value) ?
      {"hasNumber": true} :
      null;
  }

  static hasLowerCase(control) {
    var regex = /[a-z]/;
    return control.value && !regex.test(control.value) ?
      {"hasLowerCase": true} :
      null;
  }

  static hasUpperCase(control) {
    var regex = /[A-Z]/;
    return control.value && !regex.test(control.value) ?
      {"hasUpperCase": true} :
      null;
  }

  static hasSpecialCharacter(control) {
    var regex = /\W/;
    return control.value && !regex.test(control.value) ?
      {"hasSpecialCharacter": true} :
      null;
  }

  static number(control) {
    var regex = /^\d+$/;
    return control.value && !regex.test(control.value) ?
      {"number": true} : null;
  }

  static string(control) {
    var regex = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9-+.%$&#]+[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9-+.%$&#\s]+[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9-_+.%$&#]$/;
    if(control.value != undefined) {
      if(control.value.length <= 2) regex = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9-_+.%$&#]{1,2}$/;
    }
    return control.value && !regex.test(control.value) ?
      {"string": true} :
      null;
  }

  static url(control) {
    var regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
    return control.value && !regex.test(control.value) ?
      {"url": true} : null;
  }

}