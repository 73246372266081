import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.page.html',
  styleUrls: ['./order-detail.page.scss'],
})
export class OrderDetailPage implements OnInit {
	@Input() order: any;

  constructor(
  	private modalController: ModalController
  ) { }

  ngOnInit() {
  }

  async dismissModal() {
    return await this.modalController.dismiss();
  }

}
