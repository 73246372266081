import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

import { Platform, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FCM } from '@ionic-native/fcm/ngx';
import { GlobalService } from './services/global.service';
import { environment } from '../environments/environment';
import * as firebase from 'firebase';
import { Title } from '@angular/platform-browser';

import * as $ from 'jquery';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  subscriptions: any = new Subscription();
  account: any;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private fcm: FCM,
    private menuController: MenuController,
    public gs: GlobalService,
    private titleService: Title
  ) {
    this.initializeApp();
    firebase.initializeApp(environment.firebase);
    // firebase.functions().useFunctionsEmulator('http://localhost:5000');
  }

  async ngOnInit() {
    const user: any = await this.getUser();

    if (user) {
      this.subscriptions.add(firebase.firestore().collection('accounts').doc(user.uid).onSnapshot(snap => {
        if (snap.exists) {
          this.account = snap.data();
        }
      }));

      this.fcm.subscribeToTopic('global');

      this.fcm.getToken().then(token => {
        firebase.firestore().collection('accounts').doc(user.uid).update({token});
      });

      this.fcm.onNotification().subscribe(data => {
        if (data.wasTapped) {
          console.log('dataaaaa 1', JSON.stringify(data));
          console.log("Received in background");
        } else {
          console.log('dataaaaa 2', JSON.stringify(data));
          console.log("Received in foreground");
        };
      });

      this.fcm.onTokenRefresh().subscribe(token => {
        firebase.firestore().collection('accounts').doc(user.uid).update({token});
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.subscriptions.add(firebase.firestore().collection('stores').doc(this.gs.storeId).onSnapshot(snap => {
        if (snap.exists) {
          this.gs.setStore(snap.data());
          this.titleService.setTitle(snap.data().name);
          $('link[rel="icon"]').attr('href', snap.data().photoURL);
        }
      }));
    });
  }

  getUser() {
    return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      });
    });
  }

  toggleMenu() {
    this.menuController.toggle();
  }

  async logOut() {
    await firebase.firestore().collection('accounts').doc(this.account.id).update({token: null});
    await firebase.auth().signOut();
    window.location.href = '/';
  }
}
