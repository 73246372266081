import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SelectAreaPageRoutingModule } from './select-area-routing.module';

import { SelectAreaPage } from './select-area.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SelectAreaPageRoutingModule
  ],
  declarations: [SelectAreaPage]
})
export class SelectAreaPageModule {}
