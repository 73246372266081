import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { LoadingController, AlertController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';
import { CustomValidators } from '../customValidators';
import * as firebase from 'firebase';

declare var google;

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.page.html',
  styleUrls: ['./add-address.page.scss'],
})
export class AddAddressPage implements OnInit {
  saveFormGroup: FormGroup;
  autocomplete = null;
  place = null;
  constructor(
      private formBuilder: FormBuilder,
      private router: Router,
      private loadingController: LoadingController,
      private alertController: AlertController,
      private modalController: ModalController
  ) {
      this.saveFormGroup = formBuilder.group({
          name: [
            '',
            Validators.compose([CustomValidators.required])
          ],
          address: [
            '',
            Validators.compose([CustomValidators.required])
          ],
          formatted_address: [
            '',
            Validators.compose([CustomValidators.required])
          ],
          lat: [
            '',
            Validators.compose([CustomValidators.required])
          ],
          lng: [
            '',
            Validators.compose([CustomValidators.required])
          ],
          reference: [
            '',
            Validators.compose([])
          ]
      });
  }

  ngOnInit() {
      setTimeout(() => {
          this.autocomplete = new google.maps.places.Autocomplete(document.querySelector('#inputAddress'), {
              componentRestrictions: { country: 'CL' },
              types: ['address']
          });

          google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
              console.log('place_changed');
              this.place = this.autocomplete.getPlace();
              if (this.place.formatted_address) {
                  this.saveFormGroup.controls['formatted_address'].setValue(this.place.formatted_address);
                  this.saveFormGroup.controls['lat'].setValue(this.place.geometry.location.lat());
                  this.saveFormGroup.controls['lng'].setValue(this.place.geometry.location.lng());
              }
          });
      }, 1000);
  }

  async dismissModal() {
    return await this.modalController.dismiss();
  }

  async save() {
    if (!this.saveFormGroup.valid) {
        return;
    }
    const loading = await this.loadingController.create();
    await loading.present();
  	try {
        const user = firebase.auth().currentUser;
        const doc = firebase.firestore().collection('accounts').doc(user.uid).collection('addresses').doc();
        await doc.set({
            id: doc.id,
            name: this.saveFormGroup.value.name,
            address: this.saveFormGroup.value.address,
            formatted_address: this.saveFormGroup.value.formatted_address,
            lat: this.saveFormGroup.value.lat,
            lng: this.saveFormGroup.value.lng,
            reference: this.saveFormGroup.value.reference
        });

        loading.dismiss().then(async () => {
            const alert = await this.alertController.create({
                message: 'La dirección se ha agregado correctamente',
                buttons: [
                    {
                        text: 'Aceptar',
                        handler: () => {
                            this.dismissModal();
                        }
                    }
                ]
            });
            await alert.present();
      });
    } catch (e) {
        loading.dismiss().then(async () => {
            const alert = await this.alertController.create({
            message: 'Se ha producido un error, intenta nuevamente.' + e.message,
            buttons: ['Aceptar']
        });
            await alert.present();
        });
    }
  }
}
