import { Component, OnInit } from '@angular/core';
import {AlertController, LoadingController, MenuController, ModalController, Platform} from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SelectAreaPage } from '../select-area/select-area.page';
import { GlobalService } from '../services/global.service';
import * as firebase from 'firebase/app';
import { CurrencyPipe } from '@angular/common';
import { ProductDetailModalPage } from '../product-detail-modal/product-detail-modal.page';

@Component({
  selector: 'app-providers-desktop',
  templateUrl: './providers-desktop.page.html',
  styleUrls: ['./providers-desktop.page.scss'],
})
export class ProvidersDesktopPage implements OnInit {
  subscriptions: any = new Subscription();
  providers: any = [];
  categories: any = [];
  categorySelected: string;
  loading: boolean = false;
  q: any;
  storeId = null;
  store = this.gs.store;
  constructor(
      private menuController: MenuController,
      private modalController: ModalController,
      private platform: Platform,
      private router: Router,
      public gs: GlobalService,
      private route: ActivatedRoute,
      private currencyPipe: CurrencyPipe,
      private alertController: AlertController,
      private loadingController: LoadingController,

  ) {

    this.gs.activeStore().subscribe(async (st) => {
      this.store = st;
      const urlParams = new URLSearchParams(window.location.search);
      const transactionId = urlParams.get('transaction_id');
      const orderId = urlParams.get('order_id');
      if (transactionId) {
        const loading = await this.loadingController.create();
        await loading.present();
        const check = await firebase.functions().httpsCallable('checkTransaction')(
            {
              transaction_id: transactionId,
              order_id: orderId,
              token: this.store.maimagToken
            });
        loading.dismiss().then(async () => {
          console.log(check);
          let msg = 'La transacción se ha realizado exitosamente. Nos contactaremos contigo a la brevedad posible. Tu número de orden es: #' + orderId;
          if ( check.data.transaction.status === 'rejected') {
            msg = ' No se pudo finalizar el pago. ' + check.data.transaction.payment.response;
          }
          const alert = await this.alertController.create({
            message: msg,
            header: 'Transacción finalizada',
            buttons: [
              {
                text: 'Aceptar',
                handler: () => {
                  if ( window.screen.width < 950 ) {
                    this.router.navigateByUrl('products');
                  }
                }
              }
            ]
          });
          await alert.present();
        });
      } else {
        if ( window.screen.width < 950 ) {
          this.router.navigateByUrl('products');
        }
      }
    } );
  }

  async ngOnInit() {

    this.subscriptions.add(firebase.firestore().collection('stores').doc(this.gs.storeId).collection('categories').where('disabled', '==', false).onSnapshot(snap => {
      this.categories = snap.docs.map((x) => { return {id: x.id, ...x.data()}; });
      this.categories.sort( (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (b.name > a.name) {
          return 1;
        }
        return 0;
      });
    }));
    this.subscriptions.add(
        firebase.firestore().collection('stores')
            .doc(this.gs.storeId)
            .collection('products').where('disabled', '==', false)
            .onSnapshot(snap => {
              console.log('refresh');
              this.providers = snap.docs;
              this.loading = true;
        }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async selectProduct(product) {
    this.gs.product = product;
    const modal = await this.modalController.create({
      component: ProductDetailModalPage,
      cssClass: 'modal-product-detail'
    });
    return await modal.present();
  }

  openMenu() {
    this.menuController.enable(true, 'menu');
    this.menuController.open('menu');
  }

  async selectArea() {
    const modal = await this.modalController.create({
      component: SelectAreaPage
    });
    return await modal.present();
  }

  changeCategory(category) {
    this.categorySelected = this.categorySelected === category ? '' : category;
  }

  getProviders() {
    let i = this.providers.filter(obj => obj.data().deleted !== true);
    if (this.q) {
      i = i.filter((obj) => {
        return this.accentsTidy(obj.data().name.toLowerCase()).indexOf(this.q.toLowerCase()) !== -1
        || obj.data().name.toLowerCase().indexOf(this.q.toLowerCase()) !== -1;
      });
    }
    if (this.categorySelected) {
      i = i.filter((obj) => {
        return obj.data().category === this.categorySelected;
      });
    }
    return i;
  }

  async continue() {
    if (this.gs.getTotalPrice() >= this.store.minimumOrder) {
        this.router.navigateByUrl('checkout-desktop');
    } else {
      const alert = await this.alertController.create({
        message: `El pedido mínimo para esta tienda es de ${this.currencyPipe.transform(this.store.minimumOrder, 'CLP', '$')}`,
        buttons: ['Aceptar']
      });
      await alert.present();
    }
  }

  accentsTidy(s) {
    let r = s.toLowerCase();
    r = r.replace(new RegExp('\\s', 'g'), '');
    r = r.replace(new RegExp('[àáâãäå]', 'g'), 'a');
    r = r.replace(new RegExp('æ', 'g'), 'ae');
    r = r.replace(new RegExp('ç', 'g'), 'c');
    r = r.replace(new RegExp('[èéêë]', 'g'), 'e');
    r = r.replace(new RegExp('[ìíîï]', 'g'), 'i');
    r = r.replace(new RegExp('ñ', 'g'), 'n');
    r = r.replace(new RegExp('[òóôõö]', 'g'), 'o');
    r = r.replace(new RegExp('œ', 'g'), 'oe');
    r = r.replace(new RegExp('[ùúûü]', 'g'), 'u');
    r = r.replace(new RegExp('[ýÿ]', 'g'), 'y');
    r = r.replace(new RegExp('\\W', 'g'), '');
    return r;
  }

}
