import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
    product: any;
    items: any = [];
    area: any;
    areaSubject: any = new Subject<any>();
    storeSubject: any = new Subject<any>();
    itemsSubject: any = new Subject<any>();
    storeId = null;
    store = null;
  constructor() {
      const store = localStorage.getItem('storeId');
      if ( store ) {
          this.storeId = store;
      } else {
          const arr = window.location.href.split('/');
          const domain = arr[0] + '//' + arr[2];
          console.log('detected domain!!!', domain);
          if ( domain === 'https://tienda.aquavitachile.cl' || domain === 'https://aquavitachile.cl' || domain === 'https://www.aquavitachile.cl') {
              console.log('AQUAVITA!');
              this.storeId = 'zS87BCrbfGNd8kRpHLLwseuUcRU2';
          } else if ( domain === 'https://pide.sanaconfianza.cl' || domain === 'https://sanaconfianza.pideahora.cl' ) {
              this.storeId = 'sE2L40d0ijcwtFIqIKAE7PFV4tA2';
          } else if ( domain === 'https://maiporio.pideahora.cl') {
              this.storeId = 'A7GSAQaGDSuK4krLjZyI';
          } else if ( domain === 'https://jorge.pideahora.cl') {
              this.storeId = 'FWncMDUGHDLpSvdMDJEY';
          } else if ( domain === 'https://monys.pideahora.cl') {
              this.storeId = 'hKUnRt352WSiggWGxFd97RXMciX2';
          } else if ( domain === 'https://puntoneptuno.pideahora.cl') {
              this.storeId = 'q5cZaPOQ4R0cQDNt7JbR';
          } else if ( domain === 'https://goldenmarket.pideahora.cl') {
              this.storeId = 'wb1PbYN7rxOe7ibWjsqs';
          } else if ( domain === 'http://localhost:8100') {
              this.storeId = 'kveZYor3B0DDrnA3Ds28';
          } else if ( domain === 'https://reservadeloyca.pideahora.cl') {
              this.storeId = 'kveZYor3B0DDrnA3Ds28';
          }
      }
  }

  setStore(data: any) {
    this.store = data;
    this.storeSubject.next(data);
  }

  activeStore(): Observable<any> {
      return this.storeSubject.asObservable();
  }

  delete(item) {
    this.items = this.items.filter((obj) => obj.id !== item.id);
  }

  getQuantityItems() {
    let quantity = 0;
    for (const item of this.items) { quantity += item.quantity; }
    return quantity;
  }

  getTotalPrice() {
    let total = 0;
    for (const item of this.items) { total += item.subtotal; }
    return total + parseInt(this.store.shippingPrice, 0);
  }

  resetItems() {
  	this.items = [];
  }

  getItem(item) {
    return this.items.find((obj) => {
        return obj.id === item.id;
    });
  }

  async addToCart(p) {
    const item: any = { id: p.id, ...p.data()};
    console.log(this.getItem(p));
    if (!this.getItem(p)) {
        this.items.push({
            id: item.id,
            name: item.name,
            price: item.price,
            offerPrice: item.offerPrice,
            photoURL: item.photosUrl ? item.photosUrl[0] : item.photoURL || '',
            quantity: 1,
            subtotal: 1 * (item.offerPrice || item.price)
        });
    } else {
        this.getItem(p).quantity += 1;
        this.getItem(p).subtotal = this.getItem(p).quantity * (this.getItem(p).offerPrice || this.getItem(p).price);
    }
  }

  selectArea(data: any) {
  	this.areaSubject.next(data);
  }

  getSelectArea(): Subject<any> {
  	return this.areaSubject;
  }
}
