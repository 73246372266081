import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {
	constructor(private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
	  return this.isLoggedIn().then((resp) => {
          return resp;
	  });
  }

  isLoggedIn(): Promise<any> {
    return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        console.log('user', user);
        unsubscribe();
        if (user) {
          resolve({
            uid: user.uid,
            ok: true,
            phoneNumber: user.phoneNumber
          });
        }
        resolve({
          uid: null,
          ok: false,
          phoneNumber: null
        });
      });
    });
  }
}
