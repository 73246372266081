import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-select-area',
  templateUrl: './select-area.page.html',
  styleUrls: ['./select-area.page.scss'],
})
export class SelectAreaPage implements OnInit {
  area: any;
  areas: any;
  list: any = [];

  constructor(
  	private modalController: ModalController,
    private http: HttpClient,
    public globalService: GlobalService
  ) { }

  ngOnInit() {
    this.area = this.globalService.area;
    this.http.get('/assets/comunas.json').subscribe(data => {
      this.areas = data;
    });
  }

  async dismissModal() {
    return await this.modalController.dismiss();
  }

  onChange() {
    this.list = this.areas.filter((obj) => obj.name.toLowerCase().indexOf(this.area.toLowerCase()) !== -1).slice(0, 4);
  }

  select(item) {
    localStorage.setItem('area', item.name);
    this.globalService.selectArea(item.name);
    this.dismissModal();
  }

}
