import { Component, ElementRef, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-product-detail-modal',
  templateUrl: './product-detail-modal.page.html',
  styleUrls: ['./product-detail-modal.page.scss'],
})
export class ProductDetailModalPage implements OnInit {

  quantity: number = 1;
  slides: any = [];
  isScroll;
  constructor(
    private modalController: ModalController,
    private alertController: AlertController,
    private elementRef:ElementRef,
    public globalService: GlobalService
  ) {
  }

  ngOnInit() {
    this.slides = this.globalService.product.photosUrl ? 
        this.globalService.product.photosUrl : [ this.globalService.product.photoURL ];
    this.setStyles(this.globalService.store.color ? this.globalService.store.color : '#e41a68');
  }
  
  setStyles(value: string): void {
    this.elementRef.nativeElement.style.setProperty('--my-var', value); 
  }

  lessQuantity() {
    if (this.quantity > 1) {
      this.quantity--;
    }
  }

  addQuantity() {
    this.quantity++;
  }
  
  async addToCart() {
    const item: any = this.getItem();
    if (this.globalService.product.hasStock && this.globalService.product.stock < ((item && item.quantity || 0) + this.quantity)) {
      
      return this.presentAlert(`La cantidad permitida para este producto es de ${this.globalService.product.stock}`)
    }
    if (!item) {
      this.globalService.items.push({
        id: this.globalService.product.id,
        name: this.globalService.product.name,
        price: this.globalService.product.price,
        offerPrice: this.globalService.product.offerPrice,
        photoURL: this.slides[0] || '',
        quantity: this.quantity,
        subtotal: this.quantity * (this.globalService.product.offerPrice || this.globalService.product.price)
      });
    } else {
      item.quantity += this.quantity;
      item.subtotal = item.quantity * (item.offerPrice || item.price);
    }
    this.modalController.dismiss()
  }
  
  async presentAlert(msj: string) {
    const alert = await this.alertController.create({
      message: msj,
      buttons: ['Aceptar']
    });
    await alert.present();
  }

  getItem() {
    return this.globalService.items.find((obj) => {
      return obj.id == this.globalService.product.id;
    });
  }

}
